import { ADDRESS_BASE_FRAGMENT } from '@/graphql/_Fragments/Address/Base';
import { COUNTRY_BASE_FRAGMENT } from '@/graphql/_Fragments/Country/Base';
import { COMPANY_USER_ROLE_FULL_FRAGMENT } from '@/graphql/_Fragments/CompanyUserRole/Full';

export const COMMUNITY_USER_MY_PROFILE_ABOUT_FORM_FRAGMENT = `
  fragment communityUserMyProfileAboutFormFragment on CommunityUser {
    uid
    schemaCode
    bio
    address {
      ...addressBaseFragment
    }
    country {
      ...countryBaseFragment
    }
    companyRoles(filter: {state: "ACCEPTED"}) {
      ...companyUserRoleFullFragment
    }
  }
  ${COMPANY_USER_ROLE_FULL_FRAGMENT}
  ${ADDRESS_BASE_FRAGMENT}
  ${COUNTRY_BASE_FRAGMENT}
`;
